



















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import EditCustomGoalSecondContributionViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-goal-second-contribution-view-model';

@Component({
  name: 'EditCustomGoalSecondContribution',
  components: {},
})
export default class EditCustomGoalSecondContribution extends Vue {
  @Prop({ type: String, required: true })
  customGoalName!: string;

  view_model = Vue.observable(
    new EditCustomGoalSecondContributionViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
